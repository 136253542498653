import { AuthContextProvider } from './auth'
import Router from './Router'

fetch('https://api.trialer.co.uk/_allauth/browser/v1/config', {
  method: 'GET',
  credentials: 'include', // Ensures cookies are included
})
  .then(response => response.json())
  .then(data => {
    console.log('CSRF Config:', data);
  })
  .catch(error => {
    console.error('Failed to fetch CSRF token:', error);
  });

function App() {
  return (
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  )
}

export default App
