import { useUser, useConfig } from './../auth'
import { useLocation, Link } from 'react-router-dom'


export default function Account() {

  const config = useConfig()

  function NavBarItem(props) {
    const location = useLocation()
    const isActive = (props.href && location.pathname.startsWith(props.href)) || (props.to && location.pathname.startsWith(props.to))
    const cls = isActive ? 'nav-link active' : 'nav-link'
    return (
      <li className='nav-item'>
        {props.href
          ? <a className={cls} href={props.href}>{props.icon} {props.name}</a>
          : <Link className={cls} to={props.to}>{props.icon} {props.name}</Link>}
      </li>
    )
  }

  return (
    <div>
      <h1>Account</h1>

      <p>This is your account page</p>
      <NavBarItem to='/account/email' icon='📬' name='Change Email' />
      <NavBarItem to='/account/password/change' icon='🔒' name='Change Password' />
      {config.data.mfa
        ? <NavBarItem to='/account/2fa' icon='📱' name='Two-Factor Authentication' />
        : null}
      <NavBarItem to='/account/logout' icon='👋' name='Logout' />
    </div>
  )
}
